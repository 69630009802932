export default [
  {
    width: 6,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Type',
    },
    cell: {
      type: 'slot',
      name: 'type',
    },
  },
  {
    width: 7,
    header: {
      type: 'text',
      caption: 'Date',
    },
    cell: {
      type: 'slot',
      name: 'createdAt',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Email',
    },
    cell: {
      type: 'slot',
      name: 'email',
    },
  },
];
