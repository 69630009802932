//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex';
import constant from '~/const';
import tableConfig from './tableConfig';
import createUserModal from '../createUserModal.vue';

export default {
  components: {
    createUserModal,
  },

  data() {
    return {
      input: {},
      constant,
      tableConfig,
      errors: {
        createUserModal: {
          email: undefined,
          userName: undefined,
          password: undefined,
          roles: undefined,
        },
      },
      createUserModal: {
        show: false,
        loading: false,
        model: {},
      },
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapState('usersRequests', ['page', 'pages', 'data']),
    ...mapGetters('common', ['itemsPerPage']),
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),

    ...mapActions('usersRequests', {
      getRequests: 'loadData',
      removeRequest: 'removeRequest',
      setPageAction: 'setPage',
    }),

    openCreateUserModal() {
      this.errors.createUserModal.email = undefined;
      this.errors.createUserModal.userName = undefined;
      this.errors.createUserModal.password = undefined;
      this.errors.createUserModal.roles = undefined;

      this.createUserModal.model = {
        email: this.input.contact.email,
        userName: '',
        password: this.$utils.generatePassword(),
        referral: '',
        timeZone: 'Asia/Riyadh',
      };

      const countryCode = this.input?.merchantInformation?.countryCode || this.input?.traderInformation?.countryCode;
      if (Object.keys(this.constant.languages.CIS_CODES).includes(countryCode)) {
        this.createUserModal.model.language = 1;
      } else {
        this.createUserModal.model.language = 0;
      }

      if (this.input?.merchantInformation) {
        this.createUserModal.model.roles = [this.constant.users.USER_ROLE.MERCHANT];
      }

      if (this.input?.traderInformation) {
        this.createUserModal.model.roles = [this.constant.users.USER_ROLE.TRADER];
      }

      this.createUserModal.show = true;
    },

    async remove(manual = true) {
      if (manual) {
        this.confirmAction({
          title: this.$t('Decline request?'),
          text: this.$t('Are you sure you want to decline this request?'),
          callback: async () => {
            this.setGeneralLoader(true);
            try {
              await this.removeRequest({ identifiers: [this.input.id] });
              this.setSuccessNotification('Request declined');
            } catch (error) {
              this.setErrorNotification(error.message);
            }
            await this.getRequests();
            this.input = {};
            this.setGeneralLoader(false);
          },
        });
      } else {
        this.setGeneralLoader(true);
        try {
          await this.removeRequest({ identifiers: [this.input.id] });
        } catch (error) {
          this.setErrorNotification(error.message);
        }
        await this.getRequests();
        this.input = {};
        this.setGeneralLoader(false);
      }
    },

    handleRequestClick(row) {
      this.input = row;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.getRequests();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.getRequests(extendedRequestData);
    },
  },

  async mounted() {
    this.setGeneralLoader(true);
    await this.getRequests();
    this.setGeneralLoader(false);
  },
};
